import React from "react"
import SEO from "components/seo"

const TermsPage = () => (
  <>
    <SEO
      title="Sourcery | Terms and Conditions"
      description="The security and privacy of your code is the most important thing to us. Learn more about our full terms and conditions. "
    />

    <div className="bg-[#111111]">
      <div className="mx-auto px-6 pt-6 max-w-2xl">
        <h1 className="text-2xl text-left text-white 2xl:text-5xl md:text-4xl mt-28 lg:mt-40 lg:text-left">
          Terms and Conditions
        </h1>
        <p className="text-base text-left text-white lg:text-left opacity-40 mt-5">
          Last updated August 27th 2024
        </p>
      </div>

      <div className="px-6 py-6 mx-auto max-w-2xl text-white">
        Sourcery is your partner to develop higher quality code. Sourcery is
        operated by Sourcery.AI Limited, a limited company based out of the
        United Kingdom (&quot;Sourcery&quot;, &quot;We&quot;, or
        &quot;Us&quot;).
        <br></br>
        To analyse and improve your code or use any other service within our
        application (the &quot;Application&quot;) or make use of any services
        provided by us (collectively with the Application, the
        &quot;Services&quot;), you must agree to our Terms and Conditions (the
        &quot;Terms&quot;) and by our Privacy Policy.
        <b>
          If you object to anything within these terms you are prohibitted from
          using the services.
        </b>
        <br></br>
        <br></br>
        Thes Terms and Privacy Policy are subject to change by us at any time,
        effective immediately by posting through the Services. You agree to the
        Terms and any modifications by accessing the Services after any such
        change.
        <br></br>
        <br></br>
        <h3 className="text-xl">1. User Eligibility</h3>
        <br></br>
        <br></br>
        <b>1.1. Over 13 years of age</b>
        <br></br>
        <br></br>
        The Services are not intended for individuals under 13 years of age.
        <b>
          If you are under 13 years of age you are prohibitted from using the
          services.
        </b>
        , and you may not access any features that allow you to provide
        information to us or communicate and share information with other users
        of the Application (&quot;Users&quot;). By using the Services and
        agreeing to these terms you represent and warrant that you are 13 years
        of age or older and that you have the right, authority, and capacity to
        enter into, agree to, and abide by these, terms.
        <br></br>
        <br></br>
        <h3 className="text-xl">2. Use of the Services</h3>
        <br></br>
        <br></br>
        <b>2.1 Resale and Commercial Use</b>
        <br></br>
        <br></br>
        You are fully permitted to use our Application and Services for
        commercial purposes as a means to resell or build goods and services
        which you offer commercially, except for the case in which your product
        is materially similar or aims to compete with the Services offered by
        Sourcery.
        <br></br>
        <br></br>
        <b>2.2 Compliance with Applicable Laws</b>
        <br></br>
        <br></br>
        Your use of the Services, including but not limited to any software you
        develop using Sourcery, must be and shall be in accordance with any and
        all laws and regulations that apply to you and your use of the Services.
        <br></br>
        <br></br>
        <h3 className="text-xl">3. Reservation of Rights</h3>
        <br></br>
        <br></br>
        <b>3.1 Right to Terminate or Deny Service</b>
        <br></br>
        <br></br>
        You understand that we retain the right to, at our sole discretion, deny
        or block any User from using the Services and/or terminate the account
        of any existing User.
        <br></br>
        <br></br>
        <b>3.2 Rights to Disclose</b>
        <br></br>
        <br></br>
        You acknowledge and agree that we have the right to disclose information
        your provide, if required to do so by law at the request of a third
        party, or if we, in our sole discretion, believe that such disclosure
        is: 1) reasonable to comply with the law, request or orders from law
        enforcement, or any legal process (whether or not such disclosure is
        required by applicable law); 2) protect or defend our, or a third
        party’s, rights or property; or (3 protect someone’s health or safety
        <br></br>
        <br></br>
        <b>3.3 Right to Change Services</b>
        <br></br>
        <br></br>
        You acknowledge and agree that we may from time to time modify, change,
        suspend or discontinue, temporarily or permanently, in whole or in part,
        any aspect or feature of the Services without notice to you, including
        changes to usage and to access procedures. You acknowledge and agree
        that we shall not be liable to you or any third party for any such
        modification, change, suspension or discontinuance.
        <br></br>
        <br></br>
        <b>3.4 Right to Conduct Research and Collect Information</b>
        <br></br>
        <br></br>
        By accessing the Services, you agree to allow us to anonymously collect
        and use information from you and your experience with the Services to
        conduct research about the Services and to improve the products and user
        experience therein. All such information collection and use will be in
        accordance with our Privacy Policy.
        <br></br>
        <br></br>
        We will only collect information surrounding usage and errors and
        messages you send to the Coding Assistant but will not collect content
        of your code. We collect the messages you send to the Coding Assistant
        to help us improve the quality of responses we can provide you with. You
        can opt out of us collecting this information by disabling telemetry in
        your IDE, however, in order to use the Coding Assistant you must opt
        into this information being sent to third party Large Language Model
        providers. Full details are available in our Privacy Policy.
        <br></br>
        <br></br>
        <h3 className="text-xl">4. Proprietary Rights and Use License</h3>
        <br></br>
        <br></br>
        <b>4.1 Ownership</b>
        <br></br>
        <br></br>
        We and our licensors own and retain all proprietary rights in the
        Services. The Services may contain the copyrighted material, trademarks,
        and other proprietary information of us and our licensors (the “Code”).
        Except for code that is in the public domain or for which permission has
        been provided, you may not copy, modify, publish, transmit, distribute,
        perform, display, or sell any code.
        <br></br>
        <br></br>
        <b>4.2 Use License - Free Usage</b>
        <br></br>
        <br></br>
        Subject to these Terms, we grant to you a single limited, revocable,
        non-exclusive license to access the Application content, subject to the
        feature set described as available for the Free Tier of Sourcery, for
        the limited purpose of facilitating your use of the Services.
        <br></br>
        <br></br>
        <b>4.3 Use License - Pro Usage</b>
        <br></br>
        <br></br>
        Subject to these Terms, we grant to you a fixed number of limited,
        revocable, non-exclusive, paid license to access the Application
        content, subject to the feature set described as available for the Pro
        Tier of Sourcery, for the limited purpose of facilitating your use of
        the Services. The number of licenses is equal to the number of
        developers paid for within your account.
        <br></br>
        <br></br>
        <h3 className="text-xl">5. Termination</h3>
        <br></br>
        <br></br>
        <b>5.1 By User</b>
        <br></br>
        <br></br>
        You may terminate your access to the Services at any time , for any
        reason, by ceasing use of the Application or by directly requesting your
        account to be deleted by emailing hello@sourcery.ai.
        <br></br>
        <br></br>
        <b>5.2 By Us</b>
        We retain the right to cancel and terminate the account of any user who
        is violating these Terms or is in any way using the Sourcery service for
        any illegal activities or is attempting to reverse engineer any of the
        Sourcery Products or Services.
        <br></br>
        <br></br>
        <h3 className="text-xl">6. Disclaimers/Limitations</h3>
        <br></br>
        <br></br>
        <b>6.1</b>
        <br></br>
        <br></br>
        We have no special relationship with or fiduciary duty to you. You
        acknowledge that we have no control over, and no duty to take any action
        regarding:
        <br></br>
        <br></br>
        <ol>
          <li>how you may interperet or use the Application content; or</li>
          <li>
            what actions your or others may take based off of the Application
            content
          </li>
        </ol>
        <br></br>
        <br></br>
        <b>6.2</b>
        <br></br>
        <br></br>
        Under no circumstances will we or any of our affiliates, advertisers,
        promoters or distribution partners be responsible for any loss or
        damage, resulting from use of the Services.
        <br></br>
        <br></br>
        <b>6.3</b>
        <br></br>
        <br></br>
        While we strive for accuracy from our code reviews we cannot guarantee
        the validity of their results. It is the responsiblity of the end user
        to review any Sourcery suggestions or approval decisions and decide
        whether they make sense for their project.
        <br></br>
        <br></br>
        <b>6.4</b>
        <br></br>
        <br></br>
        We provide the Application and Services “as is, as available”, without
        any warranty or condition of any kind (express, implied or statutory)
        and your access of the application and services is at your own risk. We
        do not warrant that the application will meet your requirements or
        result in any particular outcome, or that the operation will be
        uninterrupted or error-free. To the fullest extent allowed by law, we
        specifically disclaim any implied warranties of titles, merchantability,
        non infringement and fitness for a particular purpose, some states do
        not allow the disclaimer of implied warranties, so the foregoing
        disclaimer may not apply to you.
        <br></br>
        <br></br>
        <h3 className="text-xl">7. Third Party Links and Services</h3>
        <br></br>
        <br></br>
        <b>7.1</b>
        <br></br>
        <br></br>
        You may be provided links to other websites or resources through the
        Services. Because we have no control over such sites and resources, you
        acknowledge and agree that we are not responsible for the availability
        of such external sites or resources, and do not endorse and are not
        responsible or liable for any content, advertising, products or other
        materials on or available from such sites or resources. You further
        acknowledge and agree that we shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be
        caused by or in connection with the use of, or reliance upon, any such
        content, goods or services available on or through any such site or
        resource.
        <br></br>
        <br></br>
        <h3 className="text-xl">8. Jurisdiction and Choice of Law</h3>
        <br></br>
        <br></br>
        These Terms are ruled by the law of the United Kingdom. In case of a
        dispute arrisingout of the Services, by using the Services you expressly
        agree that any such dispute shall be litigated in London.
        <br></br>
        <br></br>
        <h3 className="text-xl">9. Indemnity</h3>
        <br></br>
        <b>9.1 General Indemnity</b>
        <br></br>
        You agree to indemnify and hold us, our subsidiaries, affiliates,
        officers, agents, and other partners and employees, harmless from any
        loss, liability, claim, or demand, including reasonable attorney’s fees,
        made by any third party due to or arising out of your use of the
        Services in violation of these Terms and/or arising from a breach of
        these Terms and/or any breach of your representations and warranties set
        forth above.
        <br></br>
        <b>9.2 IP Indemnity</b>
        <br></br>
        Sourcery&apos;s indemnification obligations to code review users include
        any third party claim related to the use of generated code suggestions
        which infringe a third party’s intellectual property right. This
        indemnity does not apply where: (i) users knew or should have known the
        code was infringing or likely to infringe, (ii) code suggestions were
        modified, transformed, or used in combination with products or services
        not provided by Sourcery, (iii) users did not have the right to use the
        input code to generate the allegedly infringing code suggestions, (iv)
        the claim alleges violation of trademark or related rights based on
        users use of the code in trade or commerce.
        <br></br>
        <h3 className="text-xl">10. Miscellaneous and Additional Terms</h3>
        <br></br>
        <br></br>
        <b>10.1 Entire Agreement</b>
        <br></br>
        <br></br>
        These Terms contain the entire agreement between you and us regarding
        the use of the Services.
        <br></br>
        <br></br>
        <b>10.2 Severability</b>
        <br></br>
        <br></br>
        If any provision of these Terms is held to be invalid, illegal or
        unenforceable in any respect, that provision shall be limited or
        eliminated to the minimum extent necessary so that these Terms shall
        otherwise remain in full force and effect and enforceable.
        <br></br>
        <br></br>
        <b>10.3 Survival</b>
        <br></br>
        <br></br>
        Even after your access to the Services is terminated, or your use of the
        Services discontinues, these Terms will remain in effect. All terms that
        by their nature may survive termination of these Terms shall be deemed
        to survive such termination.
        <br></br>
        <br></br>
        <b>10.4 Waiver</b>
        <br></br>
        <br></br>
        Our failure to enforce any part of these Terms shall not constitute a
        waiver of our right to later enforce that or any other part of these
        Terms. Waiver of compliance in any particular instance does not mean
        that we will do so in the future. In order for any waiver of compliance
        with these Terms to be binding, we must provide you with written notice
        of such waiver, provided by one of our authorized representatives.
        <br></br>
        <br></br>
        <b>10.5 Headings</b>
        <br></br>
        <br></br>
        The section and paragraph headings in these Terms are for convenience
        only and shall not affect the interpretation of these Terms.
        <br></br>
        <br></br>
        <b>10.6 Relationship of the Parties</b>
        You and we are independent contractors, and no agency, partnership,
        joint venture, employee-employer or franchisor-franchisee relationship
        is intended or created by these Terms.
        <br></br>
        <br></br>
        Please contact us at hellosourcery.ai with any questions regarding these
        Terms.
      </div>
    </div>
  </>
)

export default TermsPage
